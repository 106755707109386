











import Vue from 'vue';

import { generateKey } from '@/helpers/StringGenerator';
import { getAccessToken } from '@/helpers/AccessTokenManager';

export default Vue.extend({
  name: 'HandleAuthorization',
  data(): {
    errorMessage: string | undefined;
  } {
    return {
      errorMessage: undefined,
    };
  },
  async mounted() {
    const processId = generateKey();
    this.$store.commit('addLoadingProcess', processId);
    try {
      const clientId = process.env.VUE_APP_WIZ_PRO_CLIENT_ID;
      if (clientId == undefined) {
        throw new Error('clientId is required but not provided');
      }
      const codeVerifier = this.$store.state.codeVerifier;
      if (codeVerifier == undefined) {
        throw new Error('codeVerifier is not saved for the request');
      }
      const authorizationCode = this.$route.query.code as (string | undefined);
      if (authorizationCode == undefined) {
        throw new Error('Authorization code is not provided'); 
      }
      const token = await getAccessToken(clientId, authorizationCode, codeVerifier);
      this.$store.commit('setToken', token);
      this.$store.commit('removeCodeVerifier');
      this.$router.push({ name: 'AreaSelector', });
    } catch (e) {
      this.errorMessage = e.message;
    } finally {
      this.$store.commit('stopLoadingProcess', processId);
    }
  },
});
